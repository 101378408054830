<template>
    <el-table
        :cell-style="{ padding: '0' }"
        :row-style="{ height: '40px' }"
        size="medium"
        border
        v-bind="$attrs"
        v-on="$listeners"
    >
        <slot></slot>
    </el-table>
</template>

<script>
export default {
    name: 'EfnTable',
};
</script>
