<template>
    <div class="btn-wrap">
        <div class="radio-btn">
            <el-radio-group v-model="val" @change="groupEmit">
                <el-radio-button class="el-btn-top" :label="i.label" v-for="(i, $index) in btns" :key="$index">
                    <div class="wrap">
                        {{ i.text }}
                    </div>
                </el-radio-button>
            </el-radio-group>
        </div>
        <div class="tips font14">
            <div v-for="(i, $idx) in btns" :key="$idx">{{ i.tips || ' ' }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EfnRadioGroups',
    props: {
        value: {
            type: Number,
            default: () => 0,
        },
        btns: {
            type: Array,
            default: () => {
                return [
                    {
                        text: '',
                        tips: '',
                        label: '',
                    },
                ];
            },
        },
    },
    watch: {
        value(value) {
            this.val = value;
        },
        val(value) {
            this.$emit('input', value);
        },
    },
    data() {
        return {
            val: this.value,
        };
    },
    methods: {
        groupEmit() {
            this.$emit('change', this.val);
        },
    },
};
</script>

<style scoped>
.btn-wrap {
    overflow: hidden;
}

.radio-btn {
    width: 100px;
    float: left;
    margin-right: 120px;
}

.wrap {
    width: 150px;
}

.tips {
    width: auto;
    float: left;
    line-height: 40px;
    color: #999;
    user-select: none;
}
</style>
<!-- 修改element-ui样式 -->
<style>
.el-radio-button:first-child .el-radio-button__inner {
    border-radius: 4px 4px 0 0;
}

.el-radio-button:last-child .el-radio-button__inner {
    padding-right: 21px;
    border-radius: 0 0 4px 4px;
}
</style>
