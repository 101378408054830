<template>
    <el-date-picker
        value-format="yyyy-MM-dd"
        :editable="false"
        type="daterange"
        :unlink-panels="true"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        v-bind="$attrs"
        v-on="$listeners"
    />
</template>

<script>
export default {
    name: 'EfnDateRange',
    props: {
        showPast: {
            type: Boolean,
            default: true,
        },
        showFuture: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            pickerOptions: {
                shortcuts: this.initShortcuts(),
            },
        };
    },
    methods: {
        initShortcuts() {
            let shotCuts = [];
            if (this.showPast) {
                shotCuts = shotCuts.concat([
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                ]);
            }
            if (this.showFuture) {
                shotCuts = shotCuts.concat([
                    {
                        text: '未来一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '未来一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                ]);
            }
            return shotCuts;
        },
    },
};
</script>
